import algoliasearch from 'algoliasearch';
import { useMemo } from 'react';
import { useEnv } from './useEnv';

let cachedAlgoliaClient: ReturnType<typeof algoliasearch> | null = null;

export const useAlgoliaClient = () => {
  const { env } = useEnv();

  const searchClient = useMemo(() => {
    if (!cachedAlgoliaClient) {
      cachedAlgoliaClient = algoliasearch(
        (env.ALGOLIA_APP_ID as string) ?? '',
        (env.ALGOLIA_SEARCH_API_KEY as string) ?? '',
      );
    }
    return cachedAlgoliaClient;
  }, [env.ALGOLIA_APP_ID, env.ALGOLIA_SEARCH_API_KEY]);

  return searchClient;
};
