import { useAlgoliaClient } from '@storefront/ui-components/hooks/useAlgoliaClient';
import { useEnv } from '@storefront/ui-components/hooks/useEnv';
import useUserLocation from '@storefront/ui-components/hooks/useUserLocation';
import { checkIsSearchPage } from '@storefront/util/browser';
import type { FC } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';
import { getRouting } from '../search/routing';

interface InstantSearchWrapperProps {
  children: React.ReactNode;
}

const InstantSearchWrapper: FC<InstantSearchWrapperProps> = ({ children }) => {
  const { env } = useEnv();
  const searchClient = useAlgoliaClient();
  const routing = getRouting(env.ALGOLIA_INDEX_NAME as string);
  const location = useUserLocation();

  const isSearchPage = checkIsSearchPage();

  // Only apply routing on search pages to prevent interference with other page types
  const finalRouting = isSearchPage ? routing : false;

  return (
    <InstantSearch searchClient={searchClient} indexName={env.ALGOLIA_INDEX_NAME} routing={finalRouting}>
      <Configure
        filters={[
          location?.warehouseId ? `warehouseId:${location.warehouseId}` : '',
          location?.partnerId ? `partnerId:${location.partnerId}` : '',
        ]
          .filter(Boolean)
          .join(' AND ')}
      />

      {children}
    </InstantSearch>
  );
};

export default InstantSearchWrapper;
